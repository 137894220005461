body {
  background: #d6dadf;
  margin: auto !important;
  font-family: 'Core Sans C', sans-serif !important;
}

.header {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 94.55%;
  min-height: 54px;
  background: #0f0f59;
  width: 100% !important;
}

.header-row {
  width: 100% !important;
  min-height: 54px !important;
}

.header-right {
  text-align: right;
  padding-top: 10px !important;
}

.header-right a {
  text-align: right !important;
  white-space: nowrap;
  font-size: 13px;
  text-decoration: none;
}

.footer {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 94.55%;
  bottom: 0%;
  font-size: 13px !important;
  background: #0f0f59;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 12px 16px;
  color: #ffffff;
}

.font-13 {
  font-size: 13px !important;
}

.secondary-color {
  color: #0F0F59 !important;
}

.text-white {
  color: #ffffff !important;
}

.content {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 5.45%;
  bottom: 5.45%;

  background: #d6dadf;
}

.content-inner {
  /* Auto layout */

  /*display: flex;*/
  /*flex-direction: row;*/
  justify-content: center;
  align-items: center;

  position: absolute;
  left: calc(50% - 444px / 2);
  top: calc(50% - 403px / 2 - 33.5px);
  background: #ffffff;
  /* Depth/5 */

  box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 12px rgba(131, 144, 175, 0.3);
  border-radius: 8px;
  height: 403px;
  width: 444px;
  border-radius: 8px;
  padding: 48px;
  text-align: center;

  position: fixed;
  top: calc(25% - 48px);
}

.content-text {
  height: 106px;
  width: 348px;
  left: 48px;
  top: 48px;
  border-radius: 0px;
}

.auto-height {
  height: auto !important;
}

.text-top {
  height: 87px;
}

.text-bottom {
  height: 137px;
}

.submit-btn {
  margin-top: 23px !important;
}

.text-bottom,
.width-100 {
  width: 100%;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.footer-icons {
  display: inline-block !important;
  margin: 2px !important;
}

.pwd-header-text {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 12px;
}

.pwd-input,
.input {
  padding: 10px 0px 15px;
}

.pwd-policy-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px;
  gap: 6px;
}

#password-icon-button {
  top: 29px !important;
}

.recaptcha,
div.recaptcha>div>div>div {
  width: auto !important;
  text-align: center !important;
}

.button div {
  width: revert;
}